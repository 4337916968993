<template>
  <router-view/>
</template>

<style lang="stylus">
*
  box-sizing border-box
body
  margin 0
  padding 0
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin 0
  padding 0
</style>
